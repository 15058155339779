import { observer } from "mobx-react-lite"
import * as React from "react"
import "./InputField.scss"

interface Props {
  type: string
  value: string | number
  placeholder?: string
  onKeyDown?(e): void
  onChange(value: string | number)
}

const InputField: React.FC<Props> = observer(
  ({ type, value, onKeyDown, onChange, placeholder }) => {
    return (
      <div className="input-container">
        <input
          type={type}
          value={value}
          onKeyDown={onKeyDown}
          onChange={e => onChange(e.target.value)}
          placeholder={placeholder || ""}
        />
      </div>
    )
  }
)

export default InputField
